import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import './header.css'

const Header = ({ siteTitle }) => (
  <header className="header">
    <div className="headercontent">
      {/* <h1 className="logowrapper">
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          <img
            alt="Hedges Digital Logo"
            className="m0"
            src="/static/0de38233219931cc09ae486fadd36990/530a5/logo_white.webp"
          />
        </Link>
      </h1> */}
      <nav>
        <Link to="#about" className="pagelink">about</Link>
        <Link to="#skillset" className="pagelink">skillset</Link>
        <Link to="#projects" className="pagelink">projects</Link>
        <Link to="#contact" className="pagelink">contact</Link>
      </nav>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
